<template>
  <div>
    <div class="position-relative pt-2">
      <div
        id="map"
        class="map-div"
      />
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    centerOfCircle: {
      type: Object,
      required: true,
    },
    radius: {
      type: Number,
      required: true,
    },
    id: {
      type: String || Function || HTMLInputElement,
      required: true,
    },
  },
  data() {
    return {
      map: '',
      marker: [],
      markers: [],
      drawingManager: {},
      selectedRadius: this.radius,
      center: {},
      locationAddress: '',
    }
  },
  watch: {
    centerOfCircle: {
      handler() {
        this.center = this.centerOfCircle
      },
      immediate: true,
      deep: true,
    },
    'center.lat': {
      handler() {
        this.$emit('circleCenterSetFunc', this.center)
      },
    },
    locationAddress() {
      this.$emit('locationAddressSetFunc', this.locationAddress)
    },
  },
  mounted() {
    this.initAutocomplete()
    this.$emit('circleCenterSetFunc', this.center)
    // this.$emit('circleRadiusSetFunc', this.selectedRadius)
  },
  methods: {
    selectedRadiusFunc(radius) {
      this.selectedRadius = radius
      this.circle.setMap(null)
      this.circleFunc()
      this.distanceChanged(this.selectedRadius)
      this.$emit('circleRadiusSetFunc', this.selectedRadius)
    },
    initAutocomplete() {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(document.getElementById('map'), {
        center: { lat: this.center.lat, lng: this.center.lng },
        zoom: 11,
        mapTypeId: 'roadmap',
      })
      // Create the search box and link it to the UI element.
      const input = document.getElementById(this.id)
      // eslint-disable-next-line no-undef
      const searchBox = new google.maps.places.SearchBox(input)
      this.shapes = []
      this.map.addListener('bounds_changed', () => {
        searchBox.setBounds(this.map.getBounds())
      })

      let markers = []

      // Listen for the event fired when the user selects a prediction and retrieve
      // more details for that place.
      searchBox.addListener('places_changed', () => {
        const places = searchBox.getPlaces()
        // Clear out the old markers.
        markers.forEach(marker => {
          marker.setMap(null)
        })
        markers = []

        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds()
        places.forEach(place => {
          if (!place.geometry || !place.geometry.location) {
            console.log('Returned place contains no geometry')
            return
          }

          if (place.geometry.viewport) {
            // Only geocodes have viewport.
            bounds.union(place.geometry.viewport)
          } else {
            bounds.extend(place.geometry.location)
          }
          this.center.lat = place.geometry.location.lat()
          this.center.lng = place.geometry.location.lng()
          this.locationAddress = place.formatted_address
          // this.$emit('circleCenterSetFunc', this.center)
        })
        this.map.fitBounds(bounds)
        this.map.setZoom(11)
      })
      this.drawCircle(this.centerOfCircle.lat, this.centerOfCircle.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    distanceChanged(val) {
      this.circle.set('radius', Number(val * 1000))
    },
    circleFunc() {
      this.drawCircle(this.center.lat, this.center.lng)
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'dragend', () => {
        this.center.lat = this.circle.getCenter().lat()
        this.center.lng = this.circle.getCenter().lng()
      })
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(this.circle, 'radius_changed', () => {
        this.selectedRadius = this.circle.getRadius()
      })
    },
    drawCircle(lat, lng) {
      // eslint-disable-next-line no-undef
      this.circle = new google.maps.Circle({
        strokeColor: '#ed1c24',
        strokeOpacity: 0.2,
        strokeWeight: 2,
        fillColor: '#ed1c24',
        fillOpacity: 0.4,
        map: this.map,
        zIndex: 1,
        center: { lat, lng },
        radius: this.selectedRadius * 1000,
      })
      this.circle.setMap(this.map)
    },
    devicesMarkerFunc(devicesPaths) {
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.markers = []
      // eslint-disable-next-line no-undef,no-plusplus
      for (let i = 0; i < devicesPaths.length; i++) {
        // eslint-disable-next-line no-undef
        this.markers.push(new google.maps.Marker({
          position: devicesPaths[i],
          title: devicesPaths[i].name,
        }))
        // this.marker.setMap(this.map)
      }
      this.markers.forEach(marker => {
        marker.setMap(this.map)
      })
    },
    async  changeHandler() {
      this.circle.setMap(null)
      this.markers.forEach(marker => {
        marker.setMap(null)
      })
      this.selectedRadius = 0
    },
  },
}
</script>

<style lang="scss">
@import "src/assets/scss/style";
.map-com {
  #map {
    height: 100%;
  }

  .map-div {
    height: 250px !important;
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
  }
}

</style>
