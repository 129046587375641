<template>
  <div class="edit-target-ad">
    <b-card>
      <div class="heading">
        <p>
          Edit Target Ad
        </p>
      </div>
      <!-- form -->
      <validation-observer ref="editTargetAd">
        <b-form
          class="mt-2"
          style="
          width: 100%"
          @submit.prevent
        >
          <b-col
            md="6"
            class="mx-auto"
          >
            <b-form-group
              text-bolder
              label="Target Ad Name"
              label-for="blog-edit-title"
              class="mb-2 campaign-label"
            >
              <validation-provider
                v-slot="{ errors }"
                name="Target Ad Name"
                rules="required"
              >
                <b-skeleton
                  v-if="isTargetAds"
                  type="input"
                />
                <b-form-input
                  v-else
                  id="blog-edit-title"
                  v-model="postData.name"
                  placeholder="Please enter Target Ad name here"
                  style="height: 42px"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-row class="mb-2">
              <b-col
                id="calender"
                cols="6"
              >
                <b-form-group
                  text-bolder
                  label="Date"
                  label-for="Date"
                  class="campaign-label"
                >
                  <validation-provider
                    name="Date"
                  >
                    <b-skeleton
                      v-if="isTargetAds"
                      type="input"
                    />
                    <b-form-datepicker
                      v-else
                      v-model="dateTimeSelectedFromSelectors.date"
                      placeholder="Choose a date"
                      locale="en"
                      :date-disabled-fn="disabledDates"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                      @input="dateChangeHandler()"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                id="time"
                cols="6"
              >
                <b-form-group
                  text-bolder
                  label="Time"
                  label-for="Time"
                  class="campaign-label"
                >
                  <b-skeleton
                    v-if="isTargetAds"
                    type="input"
                  />
                  <div v-else>
                    <b-tooltip
                      v-if="dateTimeSelectedFromSelectors.time && dateTimeSelectedFromSelectors.time.HH && dateTimeSelectedFromSelectors.time.mm "
                      target="tooltip-user-time"
                    >
                      <span>Your time will be <br> {{ dateTimeSelectedFromSelectors | momentDateForLocalTime }}</span>
                    </b-tooltip>
                    <vue-timepicker
                      id="tooltip-user-time"
                      v-model="dateTimeSelectedFromSelectors.time"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-tooltip.hover
                      :disabled="!dateTimeSelectedFromSelectors.date"
                      input-class="custom-picker"
                      format="HH:mm"
                      :minute-interval="5"
                      :hour-range="enabledHours"
                      :minute-range="enabledMinutes"
                      @change="timeChangeHandler($event)"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              text-bolder
              label="Budget"
              label-for="budget"
              class="mb-2 campaign-label"
            >
              <b-skeleton
                v-if="isTargetAds"
                type="input"
              />
              <b-input-group v-else>
                <b-input-group-prepend is-text>
                  $
                </b-input-group-prepend>
                <cleave
                  id="budget"
                  v-model="postData.budget"
                  class="form-control"
                  :raw="false"
                  lazy
                  style="height: 42px"
                  :options="number"
                  placeholder="Please enter budget for target ads"
                  @input="budgetChangeHandler($event)"
                />
              </b-input-group>
            </b-form-group>
            <div class="heading">
              <p>
                Target Audience
              </p>
            </div>
            <b-form-group
              text-bolder
              label="Location"
              label-for="blog-map"
              class="campaign-label"
            >
              <validation-provider
                id="blog-map"
                name="Location"
              >
                <b-form-input
                  id="pac-input-edit-target-ad"
                  :value="postData.location_address"
                  placeholder="Search location here"
                  type="text"
                  autocomplete="off"
                  @change="locationChangeHandler"
                />
                <b-form-group
                  text-bolder
                  label="Radius"
                  label-for="Radius"
                  class="campaign-label mt-2"
                >
                  <b-skeleton
                    v-if="isTargetAds"
                    type="input"
                  />
                  <v-select
                    v-else
                    v-model="postData.radius"
                    class="zindex-2"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    select-size="3"
                    label="label"
                    :reduce="radius => radius.id"
                    :clearable="false"
                    :options="options"
                  />
                  <b-row class="d-flex justify-content-center">
                    <b-col
                      cols="9"
                    >
                      <div class="mt-2 mx-4">
                        <b-button
                          variant="primary"
                          block
                          :disabled="addLocationSpinner"
                          class="p-1 font-medium-2"
                          @click="getAndCountOfTheDevicesByArea()"
                        >
                          <div
                            v-if="addLocationSpinner"
                            class="spinner"
                          >
                            <b-spinner
                              small
                            />
                          </div>
                          Fetch Devices
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                  <br>
                </b-form-group></validation-provider>
            </b-form-group>
          </b-col>
        </b-form>
        <b-row class="d-flex justify-content-center mb-2">
          <b-col
            cols="6"
            class="d-flex justify-content-center"
          >
            <span
              v-if="totalDevices > 0 "
              class="campaign-label font-medium-1"
            >{{ totalDevices }} devices found in this radius & approximate cost would be ${{ totalDevices*2 }} !</span>
          </b-col>
        </b-row>
      </validation-observer>
      <div class="map-div">
        <Map
          v-if="show"
          id="pac-input-edit-target-ad"
          ref="map-view-id-edit"
          :center-of-circle="postData.location"
          :radius="postData.radius"
          @circleCenterSetFunc="circleCenterSetFunc"
          @circleRadiusSetFunc="circleRadiusSetFunc"
          @devicesGetLatLong="devicesGetLatLong"
          @locationAddressSetFunc="locationAddressSetFunc"
        />
        <span
          v-else
        >
          <b-skeleton-img
            class="map-div"
          />
        </span>
      </div>
      <div />
      <!--/ form -->
      <BannerAdsMedia
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @addMediaToCampaign="addMediaToTargetAd"
      />
      <AddedMediaToCampaign
        ref="add-media-cam-ref"
        :added-media="addedFilesToCampaign"
        :max-time-limit="TimeLimitCondition.maxTargetAdsMediaCondition"
        @removeMediaFromList="deleteMediaFromTargetAd"
      />
      <div class="d-flex justify-content-center mt-0">
        <b-button
          variant="outline-primary"
          class="my-1"
          @click="reviewTargetAdsCampaign"
        >
          Review
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="m-1"
          :disabled="spinner"
          @click="editTargetAds"
        >
          <div
            v-if="spinner"
            class="spinner"
          >
            <b-spinner
              small
            />
          </div>
          Save
        </b-button>
        <b-button
          class="choose my-1"
          @click="cancelTargetAds"
        >
          Cancel
        </b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BForm, BCol, BFormGroup, BFormInput, BButton, BSpinner, BSkeleton, BSkeletonImg, BRow, BFormDatepicker, BInputGroupPrepend, BInputGroup, VBTooltip,
  BTooltip,
} from 'bootstrap-vue'
// import _ from 'lodash'
import Cleave from 'vue-cleave-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import moment from 'moment'
import Vue from 'vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import BannerAdsMedia from '@/components/common/BannerAdsMedia.vue'
import AddedMediaToCampaign from '@/components/common/AddedMediaToCampaign.vue'
import Map from '@/components/target-ads/Map.vue'
import TimeLimitCondition from '@/common/config'
import store from '@/store'
import MemberPermissions from '@/common/enums/memberPermissionsEnum'
import { getLowestTimeForTargetAd, showErrorMessage, showToast } from '@/common/global/functions'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

Vue.prototype.moment = moment
export default {
  name: 'EditTargetAd',
  components: {
    Map,
    vSelect,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    AddedMediaToCampaign,
    BButton,
    BSkeleton,
    BInputGroupPrepend,
    BInputGroup,
    Cleave,
    BannerAdsMedia,
    BSpinner,
    BSkeletonImg,
    BRow,
    BTooltip,
    VueTimepicker,
    BFormDatepicker,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  filters: {
    moment(date) {
      return moment(date).format('DD-MM-YYYY h:mm a')
    },
    momentDateForLocalTime(date) {
      return moment.utc(`${date.date} ${date.time.HH}:${date.time.mm}`, 'YYYY-MM-DD HH:mm').local().format('HH:mm')
    },
  },
  beforeRouteEnter(to, from, next) {
    const memberPermission = store.getters['user/getPermissionOfMember'](MemberPermissions.TARGET_ADS)
    // const permissionArray = groupPermissions.map(res => (res.module_permissions ? res.module_permissions : res))
    if (!memberPermission) {
      next({
        name: 'dashboard',
      })
    } else { next() }
  },
  data() {
    return {
      lowestDateTimeCanBeSelected: {
        dateTime: null,
        date: null,
        time: null,
      },
      dateTimeFromExistingTargetAd: {
        dateTime: null,
        date: null,
        time: null,
      },
      dateTimeSelectedFromSelectors: {
        date: null,
        time: {},
      },
      show: false,
      TimeLimitCondition,
      isTargetAds: false,
      spinner: false,
      addLocationSpinner: false,
      postData: {
        name: null,
        targetAdId: JSON.parse(this.$route.params.id),
        time: null,
        location: [],
        radius: 0,
        target_ads_has_media: [],
        location_address: '',
        budget: 0,
      },
      devicesPaths: [],
      addedFilesToCampaign: [],
      currentTimeSpan: '',
      limit: '',
      totalDevices: 0,
      options: [
        { label: ' 5 Km', id: 5 },
        { label: '10 Km', id: 10 },
        { label: '15 Km', id: 15 },
        { label: '20 Km', id: 20 },
      ],
      number: {
        numeral: true,
      },
      // validation
      required,
    }
  },
  computed: {
    enabledHours() {
      if (this.dateTimeSelectedFromSelectors.date === this.lowestDateTimeCanBeSelected.date) {
        return [[moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').get('hour'), 23]]
      }
      return [[0, 23]]
    },
    enabledMinutes() {
      let lowestDateTimeCanBeSelectedInRequiredFormat = null
      lowestDateTimeCanBeSelectedInRequiredFormat = moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').hours()
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.date === this.lowestDateTimeCanBeSelected.date && ((`0${lowestDateTimeCanBeSelectedInRequiredFormat}`).slice(-2).toString()) === this.dateTimeSelectedFromSelectors.time.HH.toString()) {
        return [[moment(this.lowestDateTimeCanBeSelected.time, 'HH:mm').get('minutes'), 59]]
      }
      return [[0, 59]]
    },
    isValidDateTimeSelected() {
      if (this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.time.mm) {
        const selectedDateTime = moment.utc(`${this.dateTimeSelectedFromSelectors.date} ${this.dateTimeSelectedFromSelectors.time.HH}:${this.dateTimeSelectedFromSelectors.time.mm}`, 'YYYY-MM-DD HH:mm')
        return (selectedDateTime.isAfter(this.lowestDateTimeCanBeSelected.dateTime) || selectedDateTime.isSame(this.lowestDateTimeCanBeSelected.dateTime.format()))
      }
      return false
    },
  },
  watch: {
    devicesPaths() {
      if (this.$refs['map-view-id-edit']) {
        this.$refs['map-view-id-edit'].devicesMarkerFunc(this.devicesPaths)
      }
    },
    'postData.radius': {
      handler(value) {
        if (this.$refs['map-view-id-edit']) {
          this.$refs['map-view-id-edit'].selectedRadiusFunc(value)
        }
      },
    },
  },
  created() {
    this.getTargetAds(this.$route.params.id)
    this.updateTimeCanBeSelected()
  },
  methods: {
    formatDateTimeInFormData() {
      if (this.dateTimeSelectedFromSelectors.date && this.dateTimeSelectedFromSelectors.time && this.dateTimeSelectedFromSelectors.time.HH && this.dateTimeSelectedFromSelectors.time.mm) {
        this.postData.time = moment.utc(`${this.dateTimeSelectedFromSelectors.date} ${this.dateTimeSelectedFromSelectors.time.HH}:${this.dateTimeSelectedFromSelectors.time.mm}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD[T]HH:mm')
      }
    },
    timeChangeHandler(value) {
      if (moment(value)) {
        if (`${value.data.HH}:${value.data.mm}` !== this.dateTimeFromExistingTargetAd.time) {
          this.devicesPaths = []
          this.totalDevices = 0
        }
      }
    },
    dateChangeHandler() {
      this.devicesPaths = []
      this.totalDevices = 0
    },
    updateTimeCanBeSelected() { // will update datetime object according to prior time condition
      const lowestDateTime = getLowestTimeForTargetAd()
      this.lowestDateTimeCanBeSelected.dateTime = lowestDateTime
      this.lowestDateTimeCanBeSelected.date = lowestDateTime.format('YYYY-MM-DD')
      this.lowestDateTimeCanBeSelected.time = lowestDateTime.format('HH:mm')
    },
    budgetChangeHandler(value) {
      if (value && this.targetads.target_ad_budget) {
        if (Number(value.replaceAll(',', '')) !== Number(this.targetads.target_ad_budget)) {
          this.devicesPaths = []
          this.totalDevices = 0
        }
      }
    },
    locationChangeHandler() {
      this.$refs['map-view-id-edit'].changeHandler()
      this.postData.location = {}
      this.postData.location_address = null
      this.postData.radius = 0
      this.totalDevices = 0
    },
    disabledDates(ymd, date) { // Will check if the date can be selected or not
      return (moment(date).isBefore(moment(this.lowestDateTimeCanBeSelected.date, 'YYYY-MM-DD')))
    },
    getTargetAds(id) {
      this.isTargetAds = true
      return this.$axios
        .get(`target-ads/get-target-ad/${id}`)
        .then(async ({ data }) => {
          // eslint-disable-next-line prefer-destructuring
          this.targetads = data.data
          this.postData = {
            ...this.postData, name: this.targetads.name, time: this.targetads.time, radius: this.targetads.radius, budget: this.targetads.target_ad_budget, location_address: this.targetads.location_address,
          }
          this.dateTimeFromExistingTargetAd.date = moment.utc(this.postData.time)
          this.dateTimeFromExistingTargetAd.date = moment.utc(this.postData.time).format('YYYY-MM-DD')
          this.dateTimeFromExistingTargetAd.time = moment.utc(this.postData.time).format('HH:mm')
          this.dateTimeSelectedFromSelectors.time.HH = moment.utc(this.postData.time).format('HH')
          this.dateTimeSelectedFromSelectors.time.mm = moment.utc(this.postData.time).format('mm')
          this.dateTimeSelectedFromSelectors.date = moment.utc(this.postData.time).format('YYYY-MM-DD')
          const array = (this.targetads.location.slice(6, -1)).split(',')
          const devicesArray = this.targetads.target_ads_has_device
          this.totalDevices = this.targetads.target_ads_has_device.length
          await this.getCircleCenterPoints(array)
          await this.getSelectedDevicesByArea(devicesArray)
          this.addedFilesToCampaign = this.targetads.target_ads_has_media.map(res => res.media)
          this.updateIdsOfMediaInPayload()
          this.isTargetAds = false
        }).catch(response => (this.$swal(response.message)))
    },
    getCircleCenterPoints(array) {
      const emptyArray = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < array.length; i++) {
        const subArray = array[i].split(' ')
        emptyArray.push({ lat: JSON.parse(subArray[1]), lng: JSON.parse(subArray[0]) })
      }
      if (emptyArray.length > 0) {
        this.show = true
      }
      // eslint-disable-next-line prefer-destructuring
      this.postData.location = emptyArray[0]
    },
    getSelectedDevicesByArea(array) {
      let locationPoint = ''
      const emptyArray = []
      this.devicesPaths = []
      // eslint-disable-next-line no-restricted-syntax
      for (const device of array) {
        locationPoint = device.device.location
        const points = (locationPoint.slice(6, -1)).split(',')
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < points.length; i++) {
          const subArray = points[i].split(' ')
          emptyArray.push({ lat: JSON.parse(subArray[1]), lng: JSON.parse(subArray[0]), name: device.device.name })
        }
      }
      this.devicesPaths = JSON.parse(JSON.stringify(emptyArray))
    },
    updateIdsOfMediaInPayload() {
      this.postData.target_ads_has_media = this.addedFilesToCampaign.map(res => res.id)
    },
    async editTargetAds() {
      this.$refs.editTargetAd.validate().then(async success => {
        if (success) {
          this.spinner = true
          if (this.postData.radius !== 0) {
            if (this.totalDevices === 0) {
              showToast('Edit Target Ad', 'Cannot update with 0! devices', 'danger', 4000)
            } else if (!this.addedFilesToCampaign.length <= 0) {
              await this.editTargetAdsData()
            } else {
              showToast('Edit Target Ad', 'Cannot update with 0! media files', 'danger', 4000)
            }
          } else {
            showToast('Edit Target Ad', 'Radius should not be Null', 'danger', 4000)
          }
          this.spinner = false
        }
      })
    },
    async editTargetAdsData() {
      this.formatDateTimeInFormData()
      this.postData.target_ad_budget = Number(this.postData.budget.replaceAll(',', ''))
      try {
        await this.$axios
          .put('/target-ads/update-target-ad', this.postData)
        showToast('Edit Target Ad', 'Target Ad has been edited successfully!')
        await this.$router.push('/target-ads')
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Edit Target Ad', message.toString(), 'danger')
        }
      }
    },
    async getAndCountOfTheDevicesByArea() {
      this.formatDateTimeInFormData()
      this.addLocationSpinner = true
      if (!this.isValidDateTimeSelected) {
        showToast('Edit Target Ad', 'Please select correct date and time!', 'danger', 4000)
      } else if (Object.keys(this.postData.location).length === 0) {
        showToast('Edit Target Ad', 'Please select the location!', 'danger', 4000)
      } else if (this.postData.radius === 0) {
        showToast('Edit Target Ad', 'Please select the radius!', 'danger', 4000)
      } else if (this.postData.budget === undefined || !this.postData.budget || this.postData.budget === 0 || this.postData.budget === '0' || Number(this.postData.budget.replaceAll(',', '')) > TimeLimitCondition.maxEstimatedBudget) {
        showToast('Edit Target Ad', 'Please select estimated budget correctly!', 'danger', 4000)
      } else if (Number(this.postData.budget.replaceAll(',', '')) <= 1) {
        showToast('Edit Target Ad', 'Allocated budget is insufficient for fetching devices!', 'danger', 4000)
      } else {
        try {
          const requestBody = {
            location: this.postData.location,
            radius: this.postData.radius,
            time: this.postData.time,
            budget: Number(this.postData.budget.replaceAll(',', '')),
            targetAdId: this.postData.targetAdId,
          }
          const res = await this.$axios
            .post('target-ads/get-available-devices', requestBody)
          const { data: resData } = res || {}
          const { data } = resData || {}
          const {
            deviceArray,
            count,
          } = data || {}
          this.totalDevices = count
          if (this.totalDevices === 0) {
            this.$swal('No devices available in this location!', 'danger', 4000)
            await this.devicesGetLatLong(deviceArray)
          }
          await this.devicesGetLatLong(deviceArray)
        } catch ({
          response: {
            data: {
              statusCode,
              message,
            },
          },
        }) {
          if (showErrorMessage(statusCode)) {
            showToast('Edit Target Ad', message.toString(), 'danger')
          }
          this.addLocationSpinner = false
        }
      }
      this.addLocationSpinner = false
    },
    circleCenterSetFunc(center) {
      this.postData.location = center
    },
    circleRadiusSetFunc(radius) {
      this.postData.radius = Number(radius / 1000)
    },
    locationAddressSetFunc(address) {
      this.postData.location_address = address
    },
    devicesGetLatLong(array) {
      this.devicesPaths = []
      const paths = []
      array.forEach(data => {
        paths.push({ lat: data.location.y, lng: data.location.x, name: data.name })
      })
      this.devicesPaths = JSON.parse(JSON.stringify(paths))
      return this.devicesPaths
    },
    cancelTargetAds() {
      this.$router.push('/target-ads')
    },
    addMediaToTargetAd(media) {
      this.addedFilesToCampaign.push(media)
      this.updateIdsOfMediaInPayload()
    },
    deleteMediaFromTargetAd(index) {
      this.addedFilesToCampaign.splice(index, 1)
      this.updateIdsOfMediaInPayload()
    },
    async reviewTargetAdsCampaign() {
      if (this.addedFilesToCampaign) {
        await this.$store.dispatch('lightBox/lightBoxData', this.addedFilesToCampaign)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.edit-target-ad{
  .time-picker{
    width: 100%;
    .custom-picker {
      padding: 0.438rem 1rem;
      background-clip: padding-box;
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      height: 40px;
      width: 100%;
      font-size: 1.1rem;
      color: #9d9ba6;
      font-weight: 400;
    }
  }
  #map {
    height: 100%;
  }
  .map-div{
    height: 400px !important;
  }
  .heading{
    font-size: 20px;
    line-height: 44px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat",serif;
    text-align: center;
  }
  .vs__search{
    height: 35px !important;
  }
  //.form-control{
  //  height: 42px !important;
  //}
  .b-skeleton-img{
    height: 400px !important;
  }
  .campaign-label{
    font-size: 13px;
    line-height: 18px;
    color: #323232;
    font-weight: 600;
    font-family: "Montserrat";
  }
  #calender {
    .dropdown-menu{
      z-index: 12 !important;
    }
  }
  #time{
    .vue__time-picker .controls .char{
      display: none !important;
    }
  }
}
</style>
